.hint[data-v-efcd7f1c] {
  font-size: 13px;
  color: var(--themeColor, #17a2b8);
}
.hint .icon-gantanhao1[data-v-efcd7f1c] {
  font-size: 13px;
  margin-right: 3px;
}
.top_select[data-v-efcd7f1c] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.dialog-footer[data-v-efcd7f1c] {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}